<template>
  <v-row>
    <v-card
      v-if="!loadingProfile"
      :style="{
        height: $vuetify.breakpoint.mobile? '80px' : '100px',
        width: $vuetify.breakpoint.mobile? '60px' : '80px',
        overflow: 'hidden',
        padding: '2px',
      }"
      elevation="0"
      @click="openSimilarProfileImageCropper"
    >
      <v-img
        ref="face"
        :src="similarProfileImage"
        class="rounded grey lighten-2 d-flex align-end "
        style="width: 100%; height: 100%; object-fit: cover"
      />
    </v-card>

    <v-col v-else>
      <v-skeleton-loader
        type="image"
        :style="{
          'aspect-ratio': '1',
          height: $vuetify.breakpoint.mobile? '80px' : '100px',
          width: $vuetify.breakpoint.mobile? '60px' : '80px',
          margin: '4px',
        }"
      />
    </v-col>
  </v-row>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SimilarSuperProfileListFace',
  props: {
    superProfileId: { type: String, required: true },
  },
  data() {
    return {
      loadingProfile: false,
      error: undefined,
    };
  },
  computed: {
    ...mapGetters({
      superProfileImages: 'faceid/superProfileImages',
      getSimilarSuperProfile: 'faceid/getSimilarSuperProfile',
    }),
    similarSuperProfile() {
      return this.getSimilarSuperProfile(this.superProfileId);
    },
    similarProfileImage() {
      const similarSuperProfileImagesById = this.superProfileImages
        .find((profile) => profile.superProfileId === this.superProfileId);

      return similarSuperProfileImagesById?.profileImage;
    },
  },
  created() {
    this.loadingProfile = true;

    this.fetchSimilarSuperProfile(this.superProfileId).then(() => {
      this.getSuperProfileImages();
    }).catch(() => {
      this.loadingProfile = false;
      this.error = true;
    });
  },
  methods: {
    ...mapActions({
      fetchSimilarSuperProfile: 'faceid/fetchSimilarSuperProfile',
      fetchSuperProfileImages: 'faceid/fetchSuperProfileImages',
      fetchSuperProfileImage: 'faceid/fetchSuperProfileImage',
    }),
    getSuperProfileImages() {
      this.fetchSuperProfileImages({
        images: this.similarSuperProfile?.images,
        superProfileId: this.superProfileId,
      }).then(() => {
        this.loadingProfile = false;
        this.getSuperProfileImage();
      });
    },
    getSuperProfileImage() {
      const { profile_image_url: profileImageUrl } = this.similarSuperProfile;

      this.fetchSuperProfileImage({ superProfileId: this.superProfileId, profileImageUrl })
        .then(() => {
          this.loadingProfile = false;
        });
    },
    openSimilarProfileImageCropper() {
      if (!this.loadingProfile) {
        this.$emit('openSimilarProfileImageCropper', this.superProfileId);
      }
    },
  },
};

</script>
