var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(!_vm.loadingProfile)?_c('v-card',{style:({
      height: _vm.$vuetify.breakpoint.mobile? '80px' : '100px',
      width: _vm.$vuetify.breakpoint.mobile? '60px' : '80px',
      overflow: 'hidden',
      padding: '2px',
    }),attrs:{"elevation":"0"},on:{"click":_vm.openSimilarProfileImageCropper}},[_c('v-img',{ref:"face",staticClass:"rounded grey lighten-2 d-flex align-end ",staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":_vm.similarProfileImage}})],1):_c('v-col',[_c('v-skeleton-loader',{style:({
        'aspect-ratio': '1',
        height: _vm.$vuetify.breakpoint.mobile? '80px' : '100px',
        width: _vm.$vuetify.breakpoint.mobile? '60px' : '80px',
        margin: '4px',
      }),attrs:{"type":"image"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }