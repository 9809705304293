<template>
  <div>
    <super-profile-edit
      v-if="currentMode === mode.edit"
      :key="superProfileId"
      :super-profile-id="superProfileId"
      @onGoBackButtonClicked="onGoBackButtonClicked"
      @canceled="onEditCanceled"
      @edited="onSuperProfileEdited"
    />
    <super-profile-viewer
      v-else
      :key="superProfileId"
      :super-profile-id="superProfileId"
      @onEditButtonClicked="onEditButtonClicked"
      @onGoBackButtonClicked="onGoBackButtonClicked"
      @edited="onSuperProfileEdited"
    />
    <v-snackbar
      v-model="enableAlert"
      data-cy="snackbar"
      color="primary"
    >
      {{ alertMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="enableAlert = false"
        >
          <v-icon color="white">
            {{ icons.closeIcon }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mdiClose } from '@mdi/js';
import SuperProfileViewer from './SuperProfileViewer.vue';
import SuperProfileEdit from './SuperProfileEdit.vue';

export default {
  name: 'SuperProfile',
  components: {
    SuperProfileViewer,
    SuperProfileEdit,
  },
  data() {
    return {
      mode: {
        view: 0,
        edit: 1,
      },
      currentMode: 0,
      alertMessage: '',
      enableAlert: false,
      superProfileId: undefined,
      icons: {
        closeIcon: mdiClose,
      },
    };
  },
  watch: {
    '$route.params.superProfileId': {
      immediate: true,
      handler(newSuperProfileId) {
        this.superProfileId = newSuperProfileId;
      },
    },
    superProfileId() {
      if (this.superProfileId) {
        this.currentMode = this.mode.view;
      }
    },
  },
  created() {
    this.superProfileId = this.$route.params?.superProfileId;
  },
  methods: {
    onSuperProfileEdited() {
      this.currentMode = this.mode.view;
      this.enableAlert = true;
      this.alertMessage = this.$t('deconve.person.alerts.personEdited');
    },
    onEditCanceled() {
      this.currentMode = this.mode.view;
    },
    onEditButtonClicked() {
      this.currentMode = this.mode.edit;
    },
    onGoBackButtonClicked() {
      if (this.currentMode === this.mode.edit) {
        this.currentMode = this.mode.view;
      } else {
        this.$router.push({ name: 'faceid-super-profiles' });
      }
    },
  },
};
</script>
