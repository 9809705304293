<template>
  <v-dialog
    v-model="dialog"
    :max-width="$vuetify.breakpoint.mobile ? '100%' : '450px'"
    @keydown.esc="cancel"
  >
    <v-card
      class="px-8 py-4"
      variant="elevated"
      :width="$vuetify.breakpoint.mobile ? '100%' : '450px'"
      :loading="isLoading"
    >
      <v-toolbar
        color="white"
        dense
        flat
        class="align-center"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.reviewSharing') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          :icon-name="icons.close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="cancel"
        />
      </v-toolbar>

      <v-card-text
        class="px-4 mt-2"
        style="padding-bottom: 0px;"
      >
        <div class="d-flex justify-space-between align-center mt-4">
          <span
            class="font-weight-medium caption text-sm-body-1 mb-2"
          >
            {{ $t('deconve.currentSharingReviewStatus') }}
          </span>
        </div>
        <v-card
          style="border: 1px solid;"
          class="d-flex flex-column px-8"
          :style="{ borderColor: $vuetify.theme.themes.light.border }"
          elevation="0"
        >
          <v-radio-group
            v-model="currentReview.status"
            style="gap: 5px"
            class="caption text-sm-body-2"
          >
            <v-radio
              v-for="(item, index) in shareOptions"
              :key="index"
              :label="$t(item.label)"
              :value="item.value"
            />
          </v-radio-group>

          <v-textarea
            v-if="currentReview.status === 'rejected'"
            v-model="currentReview.about"
            rows="1"
            auto-grow
            :counter="maxAboutTextLength"
            :rules="aboutRules"
            :label="$t('deconve.about')"
            class="body-2 text-sm-body-1"
            outlined
          />

          <v-row
            align-content="center"
            justify="space-between"
            hide-details
            style="height: 44px; margin: 0px;"
          >
            <div
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="textBlack"
                :size="$vuetify.breakpoint.mobile ? 22 : 26"
              >
                {{ icons.tagMultiple }}
              </v-icon>
              <div
                class="subtitle-1 font-weight-bold neutralPrimary--text"
              >
                {{ $t('deconve.tags') }}
              </div>
            </div>
            <v-btn
              outlined
              color="neutral"
              :small="$vuetify.breakpoint.mobile"
              @click="openTagModal"
            >
              <v-icon :left="!$vuetify.breakpoint.mobile">
                {{ icons.tagPlus }}
              </v-icon>

              <div v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.tag.addTags') }}
              </div>
            </v-btn>
          </v-row>
          <v-row
            class="mb-4"
            style="margin: 0px"
          >
            <div class="d-flex flex-wrap responsiveTag">
              <tag
                v-for="tag in currentReview.tags"
                :key="tag.id"
                class="pr-1 pt-1"
                :tag-id="tag.id"
              />
            </div>
          </v-row>
        </v-card>
      </v-card-text>

      <v-card-text
        v-if="lastReview"
        class="px-4"
      >
        <div class="d-flex justify-space-between align-center mt-4">
          <span
            class="font-weight-medium caption text-sm-body-1 mb-2"
          >
            {{ $t('deconve.lastSharingReviewStatus') }}
          </span>
        </div>
        <v-card
          style="border: 1px solid;"
          class="d-flex flex-column px-8 py-8"
          :style="{ borderColor: $vuetify.theme.themes.light.border }"
          elevation="0"
        >
          <v-row
            align-content="center"
            justify="space-between"
            style="height: 44px;"
          >
            <div
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="textBlack"
                :size="$vuetify.breakpoint.mobile ? 18 : 22"
              >
                {{ icons.lastStatus }}
              </v-icon>
              <div class="subtitle-2 font-medium-bold neutralPrimary--text">
                {{ selectStatusTitleOnLastReview }}:
              </div>
            </div>
            <div class="d-flex align-center mx-6">
              <v-icon
                color="textBlack"
                :size="$vuetify.breakpoint.mobile ? 18 : 22"
              >
                {{ icons.dot }}
              </v-icon>
              <div class="font-weight-light caption text-sm-body-2">
                {{ selectLastReviewStatus }}
              </div>
            </div>
          </v-row>
          <v-row
            v-if="lastReview.tags.length > 0"
            class="d-flex flex-column"
            style="min-height: 44px;"
          >
            <div
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="textBlack"
                :size="$vuetify.breakpoint.mobile ? 18 : 22"
              >
                {{ icons.tagMultiple }}
              </v-icon>
              <div class="subtitle-2 font-medium-bold neutralPrimary--text">
                {{ $t('deconve.tagsUsed') }}
              </div>
            </div>
            <div class="d-flex flex-wrap align-center mx-6">
              <tag
                v-for="tag in lastReview.tags"
                :key="tag.id"
                class="pr-1 pt-1"
                :tag-id="tag.id"
                :small="true"
              />
            </div>
          </v-row>
          <v-row
            v-if="lastReview.about"
            class="mt-8"
          >
            <v-textarea
              v-model="lastReview.about"
              rows="1"
              auto-grow
              disabled
              :label="$t('deconve.about')"
              class="body-2 text-sm-body-1"
              outlined
              hide-details
            />
          </v-row>
        </v-card>
      </v-card-text>
      <v-card-actions
        class="responsiveButton"
      >
        <v-spacer />
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          color="neutral"
          @click="cancel"
        >
          {{ $t('deconve.cancel') }}
        </v-btn>

        <v-btn
          color="primary"
          :disabled="!hasChanged"
          @click="confirm"
        >
          {{ $t('deconve.confirm') }}
        </v-btn>
      </v-card-actions>
      <tags-manager-dialog ref="tagDialog" />
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import {
  mdiClose, mdiTagMultipleOutline, mdiTagPlusOutline, mdiHistory, mdiCircleSmall,
} from '@mdi/js';
import TagsManagerDialog from '@/components/TagsManagerDialog.vue';
import Tag from '@/components/Tag.vue';
import SquareButton from './SquareButton.vue';

function defaultReviewData() {
  return {
    status: '',
    about: '',
    tags: [],
  };
}

export default {
  name: 'SuperProfileShareDialog',
  components: {
    SquareButton,
    TagsManagerDialog,
    Tag,
  },
  data: () => ({
    lastReview: undefined,
    currentReview: defaultReviewData(),
    shareOptions: [
      { label: 'deconve.approved', value: 'approved' },
      { label: 'deconve.rejected', value: 'rejected' },
      { label: 'deconve.awaitingReview', value: 'waiting' },
    ],
    icons: {
      close: mdiClose,
      tagMultiple: mdiTagMultipleOutline,
      tagPlus: mdiTagPlusOutline,
      lastStatus: mdiHistory,
      dot: mdiCircleSmall,
    },
    dialog: false,
    resolve: null,
    reject: null,
    superProfileId: null,
    isLoading: false,
    maxAboutTextLength: 128,
  }),
  computed: {
    ...mapGetters({
      getSuperProfile: 'faceid/getSuperProfile',
    }),
    aboutRules() {
      return [
        (v) => (!v || (v && v.length <= this.maxAboutTextLength))
          || this.$t('deconve.inputRules.about', [this.maxAboutTextLength]),
      ];
    },
    superProfile() {
      const superProfileInfo = this.getSuperProfile(this.superProfileId);

      return superProfileInfo;
    },
    selectLastReviewStatus() {
      switch (this.lastReview?.status) {
        case 'approved':
          return this.$t('deconve.approved');
        case 'rejected':
          return this.$t('deconve.rejected');
        case 'waiting':
          return this.$t('deconve.awaitingReview');
        default:
          return '';
      }
    },
    selectStatusTitleOnLastReview() {
      const isOnMobile = this.$vuetify.breakpoint.mobile;

      return isOnMobile ? this.$t('deconve.status') : this.$t('deconve.sharingStatus');
    },
    hasChanged() {
      if (!this.superProfile?.sharing_status?.current_review) return false;

      const {
        sharing_status: {
          current_review: currentReview,
        },
      } = this.superProfile;

      return (this.currentReview.status !== currentReview.status
            || this.currentReview.about !== currentReview.about
            || this.currentReview.tags !== currentReview.tags);
    },
  },
  methods: {
    ...mapActions({
      reviewSuperProfileSharing: 'faceid/reviewSuperProfileSharing',
    }),
    open({ superProfileId }) {
      this.superProfileId = superProfileId;

      const {
        current_review: currentReview,
        last_review: lastReview,
      } = this.superProfile.sharing_status;

      this.currentReview = currentReview ? { ...currentReview } : defaultReviewData();
      this.lastReview = lastReview ? { ...lastReview } : undefined;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.isLoading = true;
      const sharingOptions = {
        status: this.currentReview.status,
        about: this.currentReview.about,
        tags: this.currentReview.tags,
      };

      this.reviewSuperProfileSharing({
        superProfileId: this.superProfileId,
        sharingOptions,
      }).then(() => {
        this.isLoading = false;
        this.dialog = false;
        this.resolve(true);
      });
    },
    cancel() {
      this.resolve(true);
      this.dialog = false;
    },
    openTagModal() {
      this.$refs.tagDialog.open(this.currentReview.tags, true).then((response) => {
        if (response) {
          this.currentReview.tags = response;
        }
      });
    },
  },
};
</script>

<style>
  @media (max-width: 300px) {
    .responsiveTag{
      justify-content: center !important;
      margin-left: -33px !important;
    }
    .responsiveButton {
      flex-direction: column !important;
    }
  }
</style>
