<template>
  <v-col
    width="100%"
  >
    <v-row
      align-content="center"
      justify="space-between"
      style="height: 64px"
    >
      <div
        v-if="!isLoading"
        class="d-flex align-center"
      >
        <v-icon
          class="mr-2"
          color="neutralPrimary"
          :size="$vuetify.breakpoint.mobile? 16 : 20"
        >
          {{ icons.similarSuperProfiles }}
        </v-icon>
        <div
          class="subtitle-1 font-weight-bold neutralPrimary--text"
        >
          {{ $tc('deconve.similarSuperProfilesFound', similarSuperProfilesList.length) }}
        </div>
      </div>
      <v-skeleton-loader
        v-else
        type="heading"
        style="flex: 1"
        max-width="300px"
      />
    </v-row>

    <v-row>
      <v-col
        v-for="(superProfile) in similarSuperProfilesList"
        :key="superProfile.id"
        cols="auto"
        class="ma-2"
      >
        <similar-super-profile-list-face
          :super-profile-id="superProfile.id"
          @openSimilarProfileImageCropper="openImageCropper"
        />
      </v-col>
    </v-row>

    <v-row v-if="showImageCropper && superProfileSelected">
      <v-col cols="12">
        <similar-super-profile-image-viewer
          :similar-super-profile-images="similarSuperProfileImagesSelected"
          :super-profile-id="superProfileSelected"
          :is-sending-profile="isSendingProfile"
          @close="showImageCropper = false"
          @mergeSuperProfile="mergeSuperProfile"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { mdiAccountMultipleOutline } from '@mdi/js';
import SimilarSuperProfileImageViewer from './SimilarSuperProfileImageViewer.vue';
import SimilarSuperProfileListFace from './SimilarSuperProfileListFace.vue';

export default {
  name: 'SimilarSuperProfilesList',
  components: {
    SimilarSuperProfileListFace,
    SimilarSuperProfileImageViewer,
  },
  props: {
    similarSuperProfilesList: { type: Array, required: true },
    superProfileId: { type: String, required: true },
    isLoading: Boolean,
  },
  data() {
    return {
      showImageCropper: false,
      superProfileSelected: '',
      isSendingProfile: false,
      icons: {
        similarSuperProfiles: mdiAccountMultipleOutline,
      },
    };
  },
  computed: {
    ...mapGetters({
      similarSuperProfilesImages: 'faceid/superProfileImages',
    }),
    similarSuperProfileImagesSelected() {
      const { images } = this.similarSuperProfilesImages
        .find((profile) => profile.superProfileId === this.superProfileSelected);

      return images;
    },
  },
  methods: {
    ...mapActions({
      fetchSuperProfile: 'faceid/fetchSuperProfile',
      editSuperProfile: 'faceid/editSuperProfile',
    }),
    openImageCropper(superProfileSelected) {
      this.showImageCropper = true;
      this.superProfileSelected = superProfileSelected;
    },
    mergeSuperProfile() {
      this.isSendingProfile = true;
      const formData = new FormData();

      formData.append('super_profiles_to_merge', this.superProfileSelected);

      this.editSuperProfile({ superProfileId: this.superProfileId, payload: formData })
        .then(() => {
          this.isSendingProfile = false;
          this.fetchSuperProfile(this.superProfileId).then(() => {
            this.$emit('edited');
            this.showImageCropper = false;
          });
        }).catch((error) => {
          this.error = error;
          this.isSendingProfile = false;
          this.showImageCropper = false;
        });
    },
  },
};

</script>
