<template>
  <v-col>
    <v-row
      align-content="center"
      justify="space-between"
      style="height: 64px"
    >
      <div
        v-if="!isLoading && superProfileImages.length > 0"
        class="d-flex align-center"
      >
        <v-icon
          class="mr-2"
          color="neutralPrimary"
          :size="$vuetify.breakpoint.mobile? 16 : 20"
        >
          {{ icons.faceRecognition }}
        </v-icon>
        <div
          class="subtitle-1 font-weight-bold neutralPrimary--text"
        >
          {{ $t('deconve.person.facesOfThisPerson') }}
        </div>
      </div>
      <v-skeleton-loader
        v-else
        type="heading"
        style="flex: 1"
        max-width="300px"
      />
    </v-row>

    <face-list
      :faces="imageWithFaces"
      :is-loading="superProfileImages.length === 0"
      @onFaceSelected="selectImage"
    />

    <v-row
      v-if="!isLoading && superProfileImages.length > 0 && imageWithFaces.length === 0"
      class="mt-8"
    >
      <alert
        type="error"
        color="warn"
      >
        <div>
          {{ $t('deconve.person.alerts.infos.noPersonFaces') }}
        </div>
      </alert>
    </v-row>

    <v-row
      align="center"
      justify="space-between"
      style="height: 64px"
    >
      <div
        v-if="!isLoading && superProfileImages.length > 0"
        class="d-flex align-center"
      >
        <v-icon
          class="mr-2"
          color="neutralPrimary"
          :size="$vuetify.breakpoint.mobile? 20 : 24"
        >
          {{ icons.imageOutline }}
        </v-icon>
        <div
          class="subtitle-1 font-weight-bold neutralPrimary--text"
        >
          {{ superProfileImages.length }} {{ $t('deconve.images') }}
        </div>
      </div>

      <div
        v-else
        style="width: 100%"
      >
        <v-skeleton-loader
          type="heading"
          style="flex: 1"
          max-width="300px"
        />
      </div>
    </v-row>

    <v-row v-if="showImageCropper">
      <image-cropper-editor
        v-if="!isLoading"
        :images="superProfileImages"
        :index="selectedImageIndex"
        :is-edit-mode="false"
        @close="showImageCropper=false"
        @changed="updateSelectedImageIndex"
      />
    </v-row>

    <image-list
      :is-edit-mode="false"
      :score-status="true"
      :is-loading="isLoading"
      :images="superProfileImages"
      @set-crop-image="selectImage"
    />

    <v-row
      align="center"
      justify="space-between"
      style="height: 64px"
    >
      <div
        v-if="!isLoading && superProfileImages.length > 0"
        class="d-flex align-center"
      >
        <v-icon
          class="mr-2"
          color="neutralPrimary"
          :size="$vuetify.breakpoint.mobile? 20 : 24"
        >
          {{ icons.superProfile }}
        </v-icon>
        <div
          class="subtitle-1 font-weight-bold neutralPrimary--text"
        >
          {{ $tc('deconve.peopleOnSuperProfile', totalPeopleOnSuperProfile) }}
        </div>
      </div>

      <div
        v-else
        style="width: 100%"
      >
        <v-skeleton-loader
          type="heading"
          style="flex: 1"
          max-width="300px"
        />
      </div>
    </v-row>

    <v-row
      v-if="!isLoading && superProfilePeople.length > 0"
      class="align-center mt-4"
    >
      <v-col
        v-for="person in superProfilePeople"
        :key="person.id"
        class="pa-1"
        style="height: fit-content"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <person-preview :id="person.id" />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-skeleton-loader
        v-for="person in 2"
        :key="person"
        :loading="true"
        type="card"
        :style="{
          'aspect-ratio': '1',
          width: `${cardPersonSize}px`,
          height: `${cardPersonSize}px`,
          margin: '4px',
        }"
      />
    </v-row>
  </v-col>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import ImageList from '@/components/ImageList.vue';
import ImageCropperEditor from '@/components/ImageCropperEditor.vue';
import FaceList from '@/components/FaceList.vue';
import Alert from '@/components/Alert.vue';

import { mdiAccountGroup, mdiImageOutline, mdiFaceRecognition } from '@mdi/js';

import { mapGetters } from 'vuex';
import PersonPreview from '../People/PersonPreview.vue';

export default {
  name: 'SuperProfileImages',
  components: {
    ImageList,
    ImageCropperEditor,
    FaceList,
    Alert,
    PersonPreview,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    isLoading: Boolean,
  },
  data() {
    return {
      showImageCropper: false,
      selectedImageIndex: 0,
      icons: {
        superProfile: mdiAccountGroup,
        imageOutline: mdiImageOutline,
        faceRecognition: mdiFaceRecognition,
      },
    };
  },
  computed: {
    ...mapGetters({
      superProfileImages: 'faceid/personImages',
      superProfileData: 'faceid/superProfileData',
    }),
    cardPersonSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 130;
        case 'sm': return 178;
        case 'md': return 186;
        case 'lg': return 198;
        case 'xl': return 230;
        default: return 230;
      }
    },
    totalPeopleOnSuperProfile() {
      return this.superProfileData?.people.length;
    },
    superProfilePeople() {
      return this.superProfileData?.people;
    },
    imageWithFaces() {
      const faces = [];

      this.superProfileImages.forEach((img, i) => {
        if (img.info?.faces.length > 0) {
          faces.push({
            image: img.originalImage || img.mediumImage || img.thumbnailImage,
            name: img.originalName || img.mediumName,
            info: img.info,
            originalHeight: img.originalHeight,
            originalWidth: img.originalWidth,
            index: i,
          });
        }
      });
      return faces;
    },
  },
  methods: {
    selectImage(index) {
      this.selectedImageIndex = index;
      this.showImageCropper = true;
    },
    updateSelectedImageIndex(index) {
      this.selectedImageIndex = index;
    },
  },
};
</script>
