var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-sm-4 pb-4 pb-sm-4",staticStyle:{"width":"100%","border":"1px solid"},style:({
    borderColor: _vm.$vuetify.theme.themes.light.border,
    backgroundColor: _vm.$vuetify.theme.themes.light.boxBackground
  }),attrs:{"elevation":"0"}},[_c('v-row',{staticStyle:{"min-width":"100%"}},[_c('v-col',{class:{'pl-1 pr-3 py-4': !_vm.isMobileVersion},attrs:{"cols":_vm.isMobileVersion ? 12 : 6,"max-width":"885px"}},[_c('div',{staticClass:"d-flex flex-wrap align-center",class:{'justify-start': _vm.imageSize < 300,
                 'profileTitle': _vm.isMobileVersion,
                 'mt-4': !_vm.isMobileVersion}},[(!_vm.isLoadingSimilarSuperProfileImage && _vm.similarSuperProfileImages.length > 0)?_c('div',{staticClass:"d-flex",class:{'mr-4 align-center': !_vm.isMobileVersion, 'align-start': _vm.isMobileVersion}},[_c('v-icon',{staticClass:"mr-2 d-sm-flex",attrs:{"color":"textBlack","size":_vm.isMobileVersion ? 20 : 24}},[_vm._v(" "+_vm._s(_vm.icons.account)+" ")]),_c('h5',{staticClass:"d-flex mr-4 text-h6 text-sm-h5 font-weight-bold textBlack--text",staticStyle:{"word-break":"break-word","line-height":"1"},style:({ 'max-width': _vm.imageSize < 300 ? '165px' : 'initial' })},[_vm._v(" "+_vm._s(_vm.currentSuperProfileName)+" ")])],1):_c('div',{staticClass:"d-none d-sm-flex align-center mr-4"},[_c('v-skeleton-loader',{staticClass:"mr-2 d-none d-md-flex",attrs:{"type":"text","width":"100"}})],1)]),_c('carousel',{staticClass:"sideBySide",attrs:{"images":_vm.currentSuperProfileImages,"index":_vm.currentSuperProfileImageIndex,"number-of-images":_vm.currentSuperProfileImages.length,"show-face-preview":false,"show-add-to-profile":false,"loading":_vm.isLoadingCurrentSuperProfileImage,"card-border-color":"","is-profile-carousel":"","carousel-height":'486px'},on:{"downloadOriginalImage":_vm.downloadCurrentSuperProfileOriginalImage,"changeCarouselImageIndex":_vm.changeCurrentSuperProfileImageIndex}})],1),_c('v-col',{class:{'pl-1 pr-3 py-4': !_vm.isMobileVersion},attrs:{"cols":_vm.isMobileVersion ? 12 : 6,"max-width":"885px"}},[_c('div',{staticClass:"d-flex flex-wrap align-center",class:{'justify-start': _vm.imageSize < 300,
                 'profileTitle': _vm.isMobileVersion,
                 'mt-4': !_vm.isMobileVersion}},[(!_vm.isLoadingSimilarSuperProfileImage && _vm.similarSuperProfileImages.length > 0)?_c('div',{staticClass:"d-flex",class:{'mr-4 align-center': !_vm.isMobileVersion, 'align-start': _vm.isMobileVersion}},[_c('v-icon',{staticClass:"mr-2 d-sm-flex",attrs:{"color":"textBlack","size":_vm.isMobileVersion ? 20 : 24}},[_vm._v(" "+_vm._s(_vm.icons.account)+" ")]),_c('h5',{staticClass:"d-flex mr-4 text-h6 text-sm-h5 font-weight-bold textBlack--text",staticStyle:{"word-break":"break-word","line-height":"1","cursor":"pointer"},style:({ 'max-width': _vm.imageSize < 300 ? '165px' : 'initial' }),on:{"click":_vm.goToSuperProfilePage}},[_vm._v(" "+_vm._s(_vm.similarSuperProfileName)+" ")])],1):_c('div',{staticClass:"d-none d-sm-flex align-center mr-4"},[_c('v-skeleton-loader',{staticClass:"mr-2 d-none d-md-flex",attrs:{"type":"text","width":"100"}})],1)]),_c('carousel',{staticClass:"sideBySide",attrs:{"images":_vm.similarSuperProfileImages,"index":_vm.similarSuperProfileImageIndex,"number-of-images":_vm.similarSuperProfileImages.length,"show-face-preview":false,"show-add-to-profile":false,"is-similar-super-profile":true,"loading":_vm.isLoadingSimilarSuperProfileImage,"card-border-color":"","carousel-height":'486px'},on:{"downloadOriginalImage":_vm.downloadSimilarSuperProfileOriginalImage,"changeCarouselImageIndex":_vm.changeSimilarSuperProfileImageIndex,"mergeSuperProfile":_vm.mergeSuperProfile}})],1),_c('div',{staticStyle:{"position":"absolute","top":"10px","right":"10px"}},[_c('square-button',{attrs:{"color":"#0000004d","icon-color":"white","icon-name":"mdi-close","fab":""},on:{"clicked":function($event){return _vm.$emit('close')}}})],1)],1),_c('confirmation-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }